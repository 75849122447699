import React, { useEffect, useState } from 'react'
import { ActionButtonTw, ActionButtonWrapper, ActionsContainer, DownloadAllButton, FlexRowTw, TableContainerTw, TagsStyle, TextStyle } from './styles'
import { useQuery } from 'react-query';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { fetchPendingOrdersData } from './script';
import CustomReactTable from 'UiComponents/Table';
import { CustomDatePicker } from 'components/UIElements';
import moment from 'moment';
import { message } from 'antd';
import { DownIcon, DownIconTable, DownloadIcon, PlusIconNew, RefundIconBlue } from 'images/icons';
import RefundPendingOrderModal from './RefundPendingOrderModal';
import CreatePendingOrderModal from './CreatePendingOrderModal';

const startCase = (input: string) =>
    input
        .split(/[_ ]+/)
        .map(word => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');


const PendingOrders = () => {
    const [pendingOrdersData, setPendingOrdersData] = useState<any>([]);
    const [fromDate, setFromDate] = useState<string | null>(moment().subtract(6, 'days').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState<string | null>(moment().format('YYYY-MM-DD'))
    const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null)
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [showRefundModal, setShowRefundModal] = useState<boolean>(false)
    const [rowDetails, setRowDetails] = useState({});
    const [showCreateOrderModal, setShowCreateOrderModal] = useState(false);


    const { data, isLoading } = useQuery<any>(['pendingOrdersData', { fromDate, toDate, pageNumber }], fetchPendingOrdersData);

    const disabledDates = (current: any) => {
        return current > moment()
    }

    const refundHandler = (rowData: any) => {
        setShowRefundModal(true);
        setRowDetails(rowData);
    }

    const createOrderHandler = (rowData: any) => {
        setShowCreateOrderModal(true);
        setRowDetails(rowData);
    }


    const columns: object[] = [
        {
            Header: <span className='pl-2'>Order ID</span>,
            accessor: 'orderId',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='pl-2'>{value ? value : '-'}</TextStyle>
            )
        },
        {
            Header: <span className='pl-2'>Created At</span>,
            accessor: 'createdAt',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='pl-2'>
                    <TextStyle>{moment(value).format('Do MMM YYYY, h:mm a')}</TextStyle>
                </TextStyle>
            )
        },
        {
            Header: <span className='pl-2'>Customer Phone no.</span>,
            accessor: 'phone',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='pl-2'>{value ? value : '-'}</TextStyle>
            )
        },
        {
            Header:
                <>
                    <div>Gateway</div>
                    <div className='pl-2'>Transaction ID | </div>
                </>,
            id: 'txnId',
            disableSortBy: true,
            accessor: (item: any) => {
                return (
                    <div>
                        <TextStyle className='pl-2 font-bold'>{item?.transaction?.gateway ? item?.transaction?.gateway : '-'}</TextStyle>
                        <TextStyle className='pl-2'>{item?.transaction?.pg_transaction_id ? item?.transaction?.pg_transaction_id : '-'}</TextStyle>
                    </div>
                )


            }
        },
        {
            Header: 'Order Details',
            disableSortBy: false,
            tooltip: 'true',
            accessor: 'lineItems',
            Cell: (itemObj: any) => {
                const randomNumber = Math.random()
                const { value, row } = itemObj;

                return (
                    <div className='cursor-pointer' onClick={(e) => { e.stopPropagation() }}>
                        {value ? <div className='flex' data-tip data-tooltip-id={`${randomNumber}`}>
                            <div>
                                <TextStyle>
                                    {`${value?.length}`} {value?.length > 1 ? 'items' : 'item'}
                                </TextStyle>
                            </div>
                            <div style={{ visibility: row?.index === hoveredRowIndex ? 'visible' : "hidden" }}>
                                <DownIconTable />
                            </div>
                        </div> : '-'}

                        <ReactTooltip id={`${randomNumber}`} place='bottom-start' border={'1px solid #EAEAEA'} opacity={1} noArrow clickable={true} style={{ background: "white", alignContent: 'left', borderRadius: '12px', boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)" }}>

                            <div className="h-fit text-clear max-w-fit" onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                <div>{value?.map((item: any) => (
                                    <>
                                        <div className='text-itemsColor font-bold'>{item?.title}</div>
                                        <div>Qty: {item?.quantity}</div>
                                    </>)
                                )}
                                </div>
                            </div>
                        </ReactTooltip>
                    </div>
                )
            },
        },
        {
            Header: <span className='pl-2'>Amount</span>,
            accessor: 'transaction',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='pl-2 font-bold'>{value?.amount ? `₹${value?.amount?.toFixed(2)}` : '-'}</TextStyle>

            )
        },
        {
            Header: <span className='pl-2'>Remarks</span>,
            accessor: 'errorLog',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='pl-2'>{value ? startCase(value) : '-'}</TextStyle>

            )
        },
        {
            Header: <span className='pl-2'>Actions</span>,
            disableSortBy: true,
            id: 'duplicateTxnId',
            sticky: 'sticky right-0',
            accessor: (rowData: any) => {
                return (
                    <ActionsContainer style={{color: '#0C64DB', fontSize: '12px'}}>
                        <ActionButtonWrapper>
                        <ActionButtonTw onClick={() => createOrderHandler(rowData)}>
                            <PlusIconNew />
                        </ActionButtonTw>
                        Retry
                        </ActionButtonWrapper>
                        


                        {rowData?.transaction?.gateway?.toUpperCase() !== "COD" && 
                        <ActionButtonWrapper>
                            <ActionButtonTw onClick={() => refundHandler(rowData)}>
                                <RefundIconBlue />
                            </ActionButtonTw>
                            Refund
                        </ActionButtonWrapper>
                        
                        }


                    </ActionsContainer>
                )
            },
        }
    ]


    useEffect(() => {
        setPendingOrdersData(data?.data?.orderPendingData ? data?.data?.orderPendingData : []);
    }, [data])



    return (
        <div className='relative mr-[-0.5rem] min-h-screen'>
            <TableContainerTw>
                <FlexRowTw>
                    <CustomDatePicker
                        disabledDate={disabledDates}
                        allowClear={false}
                        onChange={(dates) => {
                            if (dates[1].diff(dates[0], "days") > 7) {
                                message.error('Date range cannot exceed 7 days.', 1.5)
                            } else {
                                dates && dates[0]
                                    ? setFromDate(dates[0].format("YYYY-MM-DD"))
                                    : setFromDate(null);
                                dates && dates[1]
                                    ? setToDate(dates[1].format("YYYY-MM-DD"))
                                    : setToDate(null);
                            }
                        }}
                        ranges={{
                            Today: [moment(), moment()],
                            Yesterday: [
                                moment().subtract(1, "days"),
                                moment().subtract(1, "days"),
                            ],
                            "Last 7 Days": [moment().subtract(6, "d"), moment()],
                        }}
                        defaultValue={
                            [
                                moment(fromDate, "YYYY-MM-DD"),
                                moment(toDate, "YYYY-MM-DD"),
                            ]
                        }
                    />
                </FlexRowTw>

                <CustomReactTable
                    data={pendingOrdersData || []}
                    loading={isLoading}
                    columns={columns}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={data?.data?.totalRecords}
                    checkboxes={false}
                    onSelectAll={() => { }}
                    pageSize={20}
                    loadingRows={10}
                    selectedRowsKeys={[]}
                    defaultSortBy={[{ id: '', desc: false }]}
                    newState={''}
                    handleRowSelect={() => { }}
                    isChecked={false}
                    setHoveredRowIndex={setHoveredRowIndex}
                    tableMinHeight='300px'

                />

            </TableContainerTw>

            {showRefundModal && <RefundPendingOrderModal setShowRefundModal={setShowRefundModal} rowDetails={rowDetails} />}
            {showCreateOrderModal && <CreatePendingOrderModal setShowCreateOrderModal={setShowCreateOrderModal} rowDetails={rowDetails} />}
        </div>
    )
}

export default PendingOrders