import style from './MobilePreview.module.css';
import loginStyle from "./Login.module.css";
import React, { useEffect, useState } from "react";
import { IndiaFlag } from '../../../src/images/icons';
import { InfoIcon } from '../../../src/images/icons';
const srLogo = require("../../../src/images/SRLogoDark.png")
// const infoIcon=require("../../../src/images/Group.svg")


const infoIcon = <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.79456 3.22992H5.60785V4.0432H4.79456V3.22992ZM4.79456 4.85649H5.60785V7.29635H4.79456V4.85649ZM5.2012 1.1967C2.95653 1.1967 1.13477 3.01846 1.13477 5.26313C1.13477 7.50781 2.95653 9.32957 5.2012 9.32957C7.44588 9.32957 9.26764 7.50781 9.26764 5.26313C9.26764 3.01846 7.44588 1.1967 5.2012 1.1967ZM5.2012 8.51629C3.4079 8.51629 1.94805 7.05643 1.94805 5.26313C1.94805 3.46984 3.4079 2.00998 5.2012 2.00998C6.9945 2.00998 8.45435 3.46984 8.45435 5.26313C8.45435 7.05643 6.9945 8.51629 5.2012 8.51629Z" fill="#717171" />
</svg>

const MobileImagePreview = ({ shiprocketLoginPageType, subheaderText, themeColor, filesUploaded, sellerLogo }: { shiprocketLoginPageType: any, subheaderText: any, themeColor: any, filesUploaded: any, sellerLogo: any }) => {
    const imgUrl = filesUploaded.length > 0 ? filesUploaded : null
    const imgUrlLogin = filesUploaded.length > 0 ? filesUploaded : null
    console.log("check", imgUrl, imgUrlLogin)
    return (
        <div style={{position:"relative",height: "50%",width: "100%",maxHeight: "520px",
            maxWidth: "400px"}}>
        <div className={style.Container} style={{zIndex:"9999"}}>
            {<div style={{display:"flex",flexBasis:"80%", justifyContent:"center" ,alignItems:'center' }}>
                {sellerLogo && <div style={{maxWidth:"100px",maxHeight:"100px"}}><img alt="Seller-logo"
                    src={sellerLogo} /></div>}

            </div>}

            <div className="card-container-login"  >

                <div className={style.subtext}>{subheaderText}</div>


                <div className={loginStyle.inputGroup} style={themeColor ? { border: `1px solid ${themeColor}` } : { border: "1px solid var(--login-popup-primary-color)" }}>
                    <div className={loginStyle.inputAddon}>
                        <span >
                            <IndiaFlag /></span>
                        <span className="code" style={{ fontSize: "16px", fontWeight: 400 }}>+91</span>

                    </div>
                    <input maxLength={15} id="mobile" type="tel" autoComplete="tel" placeholder="10-digit phone number" className={`${loginStyle.inputLoginPopup}`} style={{ fontSize: "14px", fontWeight: 400, padding: "1px", width: "60%" }} />



                    <div className={loginStyle.inputAddonRight} style={themeColor ? {
                        borderLeft: `1px solid ${themeColor}`,
                        background: `${themeColor}`
                    } : {
                        borderLeft: "1px solid var(--login-popup-primary-color)",
                        background: "var(--login-popup-primary-color)"
                    }}>Login</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', gap: "0px", height: "30%" }}>

                    <svg width="9" height="9" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.01562 6.10938H10.5156V7.60938H9.01562V6.10938ZM9.01562 9.10938H10.5156V13.6094H9.01562V9.10938ZM9.76562 2.35938C5.62563 2.35938 2.26562 5.71938 2.26562 9.85938C2.26562 13.9994 5.62563 17.3594 9.76562 17.3594C13.9056 17.3594 17.2656 13.9994 17.2656 9.85938C17.2656 5.71938 13.9056 2.35938 9.76562 2.35938ZM9.76562 15.8594C6.45813 15.8594 3.76562 13.1669 3.76562 9.85938C3.76562 6.55188 6.45813 3.85938 9.76562 3.85938C13.0731 3.85938 15.7656 6.55188 15.7656 9.85938C15.7656 13.1669 13.0731 15.8594 9.76562 15.8594Z" fill="#929292" />
                    </svg>
                    <p className="info_text_login_popup" style={{ color: 'rgb(113, 113, 113)' }}>

                        By proceeding, you are agreeing to Shiprocket’s
                        <a href="https://checkout.shiprocket.in/terms-conditions/" target="_blank"
                            style={{ textDecoration: 'underline', fontWeight: '700', color: 'rgb(113, 113, 113)' }}> T&amp;C </a>
                        and <a href="https://www.shiprocket.in/privacy-policy/my-shiprocket/" target="_blank"
                            style={{ textDecoration: 'underline', fontWeight: '700', color: 'rgb(113, 113, 113)' }}>Privacy
                            policy</a>.</p>
                </div>



            </div>
            <div className="d-flex container2 h6" style={{ margin: '0px 0px 4px' }}>
                <p className="login-popup-private-color">Powered by</p><img className="sr-logo"
                    src={`${srLogo}`} alt="Shiprocket" />
            </div>

        </div>
        <div style={{position:"absolute" ,top:0 ,width:"100%",height:"100%", background:(imgUrl || imgUrlLogin)?"none":themeColor?`linear-gradient(0deg, ${themeColor} 2.35%, ${themeColor} 92.13%)`:"linear-gradient(0deg, var(--login-popup-primary-color) 2.35%, #FFF 92.13%)"}}>
        {(imgUrl || imgUrlLogin) &&<img style={{
            
      width: "100%",
      height: "100%",
      }}
                    src={imgUrl||imgUrlLogin} />}
        </div>
        </div>
    );
};

export default MobileImagePreview;