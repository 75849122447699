// @ts-nocheck

import React, { useState } from "react";
import { message } from "antd";
import moment from "moment";
import { CustomDatePicker } from "./styled";
import { useQuery } from "react-query";
import { TableContainerTw, TextStyle } from "pages/Orders/OrdersTab/styles";
import CustomReactTable from "UiComponents/Table";
import { ToastSuccessTick, DownloadIcon } from "images/icons";
import "../../Loader.css";
import { toast } from "react-toastify";
import { GetAuthToken } from "globalQuries";
import UploadBox from "./UploadBox";
import { fetchReportsData } from "pages/Reports/script";
const RefundsTable = () => {
  const [fromDate, setFromDate] = useState<string | null>(
    moment().subtract(6, "days").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState<string | null>(
    moment().format("YYYY-MM-DD")
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [defaultSortBy, setDefaultSortBy] = useState<any>([
    { id: "", desc: false },
  ]);
  const [viewMoreState, setViewMoreState] = useState<any>();

  const disabledDates = (current: any) => {
    return current > moment();
  };
  const reportTypeFilters = ["bulk_refund"];
  const query_params = { from_date: fromDate, toDate: toDate };
  const { data: refundData, isLoading ,refetch} = useQuery<any>(
    [
      "BulkRefundData",
      {
        pageNumber,
        reportTypeFilters,
        query_params: query_params,
      },
    ],
    fetchReportsData,
    { cacheTime: 0 }
  );
  const refetch_data = ()=>{
    refetch()
  }
  const columns: any = [
    {
      Header: <span className="pl-4">Refund Start Date</span>,
      accessor: "startDate",
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const startDate = row.original?.additionalInformation?.refundStartDate;
        return (
          <TextStyle className="pl-4 leading-10">
            {startDate ? moment(startDate).format("Do MMMM h:mm a") : "-"}
          </TextStyle>
        );
      },
    },

    {
      Header: <span className="pl-2"> Refund End Date</span>,
      accessor: "endDate",
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const endDate = row?.original?.additionalInformation?.refundEndData;
        return (
          <TextStyle className="pl-2 leading-10">
            {endDate ? moment(endDate).format("Do MMMM h:mm a") : "-"}
          </TextStyle>
        );
      },
    },

    {
      Header: <span className="pl-2">Total Transaction Count</span>,
      accessor: "additionalInformation.totalRecords",
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const totalRecords = row?.original?.additionalInformation?.totalRecords;
        return (
          <TextStyle className="pl-2 leading-10">
            {totalRecords ? totalRecords : "-"}
          </TextStyle>
        );
      },
    },

    {
      Header: <span className="pl-2">Successful Refunds</span>,
      accessor: "additionalInformation.successfulRecords",
      disableSortBy: true,
      Cell: ({ value }: any) => (
        <TextStyle className="pl-2 leading-10">{value ? value : "-"}</TextStyle>
      ),
    },
    {
      Header: <span className="pl-2"> Failed Refunds </span>,
      accessor: "additionalInformation.failedRecords",
      disableSortBy: true,
      Cell: ({ value }: any) => (
        <TextStyle className="pl-2 leading-10">{value ? value : "-"}</TextStyle>
      ),
    },
    {
      Header: <span className="pl-2">Action </span>,
      accessor: "reportLink",
      disableSortBy: true,
      Cell: ({ value }: any) =>
        value ? (
          <a
            href={value}
            onClick={() =>
              toast.success("Report downloaded successfully", {
                icon: ToastSuccessTick,
              })
            }
          >
            <DownloadIcon
              size={32}
            />
          </a>
        ) : (
          ""
        ),
    },
  ];

  return refundData?.reports?.length > 0 && !isLoading ? (
    <>
      <TableContainerTw>
        <div className="flex justify-end items-center h-10 px-4">
          <div>
            <UploadBox show_dotted_box={false} refetch_data={refetch_data}/>
          </div>
          <div className="px-4 py-2 mr-2">
            <CustomDatePicker
              allowClear={false}
              onChange={(dates: Array<moment.Moment>) => {
                if (dates[1].diff(dates[0], "days") > 60) {
                  message.error(
                    "Date range can not exceed 60 days. Please select date range less than or equal to 60 days only.",
                    1.5
                  );
                } else {
                  dates && dates[0]
                    ? setFromDate(() => {
                        return dates[0].format("YYYY-MM-DD");
                      })
                    : setFromDate(null);
                  dates && dates[1]
                    ? setToDate(() => dates[1].format("YYYY-MM-DD"))
                    : setToDate(null);
                }
              }}
              ranges={{
                Today: [moment(), moment()],
                Yesterday: [
                  moment().subtract(1, "days"),
                  moment().subtract(1, "days"),
                ],
                "Last 7 Days": [moment().subtract(6, "d"), moment()],
                "This Month": [moment().startOf("month"), moment()],

                "Last 30 Days": [moment().subtract(29, "days"), moment()],
              }}
              defaultValue={[
                moment(fromDate, "YYYY-MM-DD"),
                moment(toDate, "YYYY-MM-DD"),
              ]}
              disabledDate={disabledDates}
            />
          </div>
        </div>
        <CustomReactTable
          loading={isLoading}
          data={refundData?.reports || []}
          pageNumber={pageNumber}
          columns={columns}
          setPageNumber={setPageNumber}
          totalPages={refundData?.totalRecords}
          pageSize={20}
          defaultSortBy={defaultSortBy}
          newState={viewMoreState}
          setHoveredRowIndex={() => {}}
        />
      </TableContainerTw>
    </>
  ) : (
    <>
      <UploadBox show_dotted_box={true}  refetch_data={refetch_data}/>
    </>
  );
};

export default RefundsTable;
