import {useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {Props} from './data';
import {pathObject} from './utils';
import SubMenu from "./SubMenu";
import MenuItem from './MenuItem';


const SideBox: React.FC<Props> = ({ data, hideSideBar, defaultSelectedKey, setDefaultSelectedKey, displayAccordion, handleAccordionClick }) => {
    const [selectedSubKey, setSelectedSubKey] = useState<string>("")
    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        pathObject?.[location.pathname] ? setDefaultSelectedKey(pathObject[location?.pathname]?.[0]) : setDefaultSelectedKey('')
        pathObject?.[location.pathname] ? setSelectedSubKey(pathObject[location?.pathname]?.[1]) : setDefaultSelectedKey('')
        
        let permissionsnableRoute = localStorage?.getItem('panel')?.length && JSON?.parse(localStorage?.getItem('panel'))?.slice(1, -1)?.split(',');
            permissionsnableRoute = permissionsnableRoute?.map(function(item:string) {
                return item?.trim();
        });

        if (pathObject?.[location.pathname]?.[0] === '/') {
            if(permissionsnableRoute?.includes('dashboard')){
                navigate("/dashboard");
            }else if(permissionsnableRoute?.length) {
                navigate(`${permissionsnableRoute?.[0]}`);
            }else {
                navigate('/dashboard')
            }
        }

    }, [location.pathname]);

    return <>
        {data && data.map((keys) => {
            return <>
                <nav className="p-6 w-full flex flex-col flex-wrap -mt-10">
                    <ul className="space-y-1.5">
                        <li className={`${hideSideBar ? '-mx-2.5' : ''}`}>
                            <MenuItem callback= {handleAccordionClick} defaultSelectedKey = {defaultSelectedKey} keys ={keys}/>
                            <div
                                className={`pt-0 overflow-hidden transition-[max-height] duration-500 ease-in-out ${displayAccordion[keys.name] ? "max-h-96" : "max-h-0"}`
                                }>
                                {keys.views && !hideSideBar && keys.views.map((subkey) => {
                                    return (Object.keys(subkey)?.length ? <SubMenu subkey = {subkey} selectedSubKey = {selectedSubKey} hideSideBar = {hideSideBar}/> : null)
                                })

                                }
                            </div>
                        </li>
                    </ul>
                </nav>
            </>
        })}
    </>
}
export default SideBox