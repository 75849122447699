import axiosInstance from "HTTP/Http"



export const fetchReportsData = async ({queryKey}:any)=>{
    const userToken = localStorage.getItem("userManagementToken")
    const [unusedArgument, 
        { pageNumber, reportTypeFilters,query_params = {}}
      ] = queryKey  
    const response = await axiosInstance.get(`${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/history`,
        {
            headers : {
                "User-Token": userToken,
            },
            params:{
                pageNumber:pageNumber>=1? pageNumber-1: pageNumber,
                filterValue: reportTypeFilters && reportTypeFilters.length? reportTypeFilters[0] : undefined,
                ...query_params
            }
        }
       )       

    return response.data.data;
}

