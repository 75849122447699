import tw from "tailwind-styled-components";

export const FlexRowTw=tw.div`
    flex 
    justify-end
`

export const TableContainerTw=tw.div`
    bg-white
    h-auto
    rounded-lg
    p-5
`

export const DownloadAllButton=tw.button`
    flex
    gap-1
    h-8
    bg-white
    text-exportText
    rounded-lg
    font-normal
    px-4
    py-1
    min-w-36
    shadow-md
    border
    border-outline
    hover:bg-primary
    hover:text-white
    `

    export const TextStyle = tw.h6`
    w-max
    text-sm
    font-normal
    leading-5
    text-idColor
`

export const ActionButtonTw = tw.button`
    h-8
    w-8
    rounded-full
    bg-lightBlue
    flex
    justify-center
    items-center
    mx-2
`

export const ActionsContainer = tw.div`
    py-2
    flex
    sticky
    left-0
`
export const TagsStyle = tw.div`
    flex
    text-xs
    rounded-lg 
    px-2 py-1
    text-tagsColor
    items-center
    justify-between
    bg-tagsBg
    border-pageBorder
    border
`

export const ActionButtonWrapper = tw.div`
flex
flex-col
items-center
`