
import axiosInstance from '../../HTTP/Http';
import { downloadFile } from 'components/UIElements/function';


export const fetchOrderStatus = async ({ queryKey }: any) => {
  const [unusedArgument, { fromDate, toDate }] = queryKey

  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/order-stats`,
    {
      params: {
        startDate: fromDate,
        endDate: toDate
      }
    }
  )
  return response.data.data;
}


export const fetchDashboardData = async ({ queryKey }: any) => {
    const [unusedArgument, { fromDate, toDate, timeDuration,channelSource,shopifySessionData }] = queryKey

    const response = await axiosInstance.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/dashboard`,
      {
        params: {
          from: fromDate,
          to: toDate,
          duration:timeDuration, 
          channelSource : channelSource,
          shopifySessionData : shopifySessionData
        }
      }
    )
    return response.data.data;
}

export const fetchGraphData = async ({ queryKey }: any) => {
  const [unusedArgument, { fromDate, toDate, duration }] = queryKey

  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/dashboard`,
    {
      params: {
        from: fromDate,
        to: toDate,
        duration: duration
      }
    }
  )
  return response.data.data;
}


export const fetchTrafficAcquisitionData = async ({ queryKey }: any) => {
  const [unusedArgument, { fromDate, toDate, filter, pageNumber, pageSize }] = queryKey

  let utmGroups:any;
  
  filter.forEach((element:string, index:number) => {
    if(index > 0){
      utmGroups +=  `/${element}`
    }else {
      utmGroups =  element
    }
  });


  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/traffic-acquisition`,
    {
      params: {
        startDate: fromDate,
        endDate: toDate,
        page: pageNumber,
        utmGrp: utmGroups,
        pageSize: pageSize
      }
    }
  )
  return response.data.data;
}


export const fetchPaymentModeComparisonData = async ({ queryKey }: any) => {
  const [unusedArgument, { fromDate, toDate, tabPage2, tabPageSize2 }] = queryKey

  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/payment-mode-comparison`,
    {
      params: {
        from: fromDate,
        to: toDate,
        page: tabPage2,
        size: tabPageSize2,
      }
    }
  )
  return response.data.data;
}



export const fetchExistingCustomer = async ({ queryKey }: any) => {
  const [unusedArgument, { fromDate, toDate, tabPageSize2 }] = queryKey

  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/existing-customer-stats`,
    {
      params: {
        startDate: fromDate,
        endDate: toDate,
      }
    }
  )
  return response.data.data;
}

export const fetchDashboardDiscountData = async ({ queryKey }: any) => {
  const [unusedArgument, { fromDate, toDate, discountPage  }] = queryKey;
  try{
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/discount-analytics`,
    {
      params: {
        from: fromDate,
        to: toDate,
        page : discountPage,
        size : 10,
      }      
    }
  )
  return response.data.data;
}catch(error){
  console.log(error?.response, 'checking error inside the response data')
}
}


export const downloadConversion = async (
  fromDate: string, toDate: string, duration: string, channelSource : string,shopifySession : boolean) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/`,
      {
          params: {
              from: fromDate,
              to: toDate,
              key: 'duration',
              value: duration,
              type: 'fastrr-checkout-conversion', 
              channelSource : channelSource,
              shopifySessionData : shopifySession
          }
      }
  )
  downloadFile(response.data, 'FastrrCheckoutConversion.csv')
}

export const downloadPaymentModeConversion = async (
  fromDate: string, toDate: string) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/`,
      {
          params: {
              from: fromDate,
              to: toDate,
              type: 'PAYMENT_MODE_CONVERSION'
          }
      }
  )
  downloadFile(response.data, 'PaymentModeConversion.csv')
}

export const fetchCarouselData = async ({ queryKey }: any) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/dashboard-banner`,
  )
  return response.data.data;
}

export const downloadTrafficAcq = async (
  fromDate: string, toDate: string, filter:any) => {
  let utmGroups:any;

  filter.forEach((element:string, index:number) => {
    if(index > 0){
      utmGroups +=  `/${element}`
    }else {
      utmGroups =  element
    }
  });


  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/`,
      {
          params: {
              from: fromDate,
              to: toDate,
              key: 'utmGrp',
              value: utmGroups,
              type: 'traffic-acquisition'
          }
      }
  )
  downloadFile(response.data, 'PaymentModeConversion.csv')
}



export const downloadDiscount = async (
  fromDate: string, toDate: string) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/`,
      {
          params: {
              from: fromDate,
              to: toDate,
              type: 'discount_analytics'
          }
      }
  )
  downloadFile(response.data, 'discount_analytics.csv')
}

