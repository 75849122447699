import styled from 'styled-components'
import { Table, DatePicker, Input, Modal, Upload } from 'antd'
import tw from "tailwind-styled-components"

const { RangePicker } = DatePicker;

export const Text = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #0C3784;
`

export const CustomTable = styled(Table)`
  .ant-table {
    border: 1px solid rgba(38, 63, 151, 0.1);
    border-radius: 12px;
  }
  .ant-table-thead > tr > th {
    background: #fff;
    color: rgba(13, 57, 133, 0.5);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    /* text-transform: uppercase; */
    word-break: none;
  }

  .ant-table-tbody > tr > td {
    word-wrap: break-word;
    word-break: break-word;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #0c3784;
    text-align: left;
  }
  .ant-table-tbody > tr {
    vertical-align: center;
    border: 1px solid rgba(38, 63, 151, 0.1);
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #f1faff;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 12px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 12px;
  }
  .ant-table-tbody > tr > td > td:first-child {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }
  .ant-table-thead > tr > th:last-child {
    text-align: center;
  }
`;

export const CustomDatePicker = styled(RangePicker)`
  &.ant-picker {
    border: 1px solid #93afe0;
    border-radius: 8px;
  }
`;

export const CustomInput = styled(Input)`
    border-radius: 10px! important;
`
export const PaymentStatus = styled.div<{ textColor?: string, bgColor?: string }>`
    width: 80px;  
    height: 28px;
    border-radius:8px; 
    font-size:12px;
    line-height: 15px;
    font-weight: 600;
    margin-top:10px;
    text-align:center;
    padding:6px 0;
    background-color: ${(props: any) =>
    props.bgColor ? props.bgColor : '#F0F6FD'};
    color:${(props: any) => props?.textColor ? props?.textColor : '#000B34'
  };
`
export const BulkRefundButton = tw.button`
    flex
    items-center
    justify-center
    gap-1
    h-8
    bg-white
    text-exportText
    rounded-lg
    font-normal
    px-4
    py-1
    min-w-36
    shadow-md
    border
    border-outline
    disabled:bg-disabledButtonPrimary
    disabled:text-disabledButtonText
    disabled:hover:bg-disabledButtonPrimary
    disabled:hover:text-disabledButtonText
    disabled:shadow-none
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const UploadArea = styled.div`
  width: 100%;
  height: 200px;
  border: 2px dashed #9254de;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  margin-bottom: 20px;

  .ant-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  svg {
    font-size: 24px;
    color: #9254de;
    margin-bottom: 8px;
  }

  span {
    color: #9254de;
    font-size: 16px;
  }
`;

const OrText = styled.p`
  font-size: 16px;
  color: #8c8c8c;
  margin: 12px 0;
`;

const DownloadLink = styled.span`
  color: #9254de;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border: none; /* Remove header border */
    text-align: center;
  }

  .ant-modal-title {
    font-size: 20px;
    font-weight: bold;
  }
  .ant-modal-body {
    padding: 0;
    border-radius: 20px;
    overflow: hidden;
  }
  .ant-modal-content {
    border-radius: 10px !important;
  }
  .ant-modal-close {
    display: none; /* Remove the X close button */
  }
`;
const StyledUpload = styled(Upload)`
  .ant-upload-list-item-name {
    display: inline-block;
    /* max-width: 200px; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
  }
`;
export { ModalContent, DownloadLink, OrText, UploadArea, StyledModal, StyledUpload }