import React, { useState } from "react";
import styled from "styled-components";
import {
  CloudIconNew,
  CloudIcon,
  ToastErrorCross,
  ToastSuccessTick,
} from "images/icons";
// Styled components
import { GetAuthToken } from "globalQuries";
import { toast } from "react-toastify";
import { handleBulkRefundSampleFile } from "./script";

import {
  ModalContent,
  UploadArea,
  OrText,
  DownloadLink,
  StyledModal,
  StyledUpload,
} from "./styled";
import { PickrrButton } from "components/UIElements";

interface UploadBoxProps {
  show_dotted_box: boolean;
  refetch_data?:()=>void
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  border: 2px dashed #d9d9d9;
  background-color: #fafafa;
`;

const IconWrapper = styled.div`
  font-size: 48px;
  color: #8c8c8c;
  margin-bottom: 8px;
`;

const Message = styled.p`
  font-size: 16px;
  color: #8c8c8c;
  margin-bottom: 8px;
`;

const UploadBox: React.FC<UploadBoxProps> = ({ show_dotted_box, refetch_data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const auth = GetAuthToken();
  const userToken = localStorage.getItem("userManagementToken");
  const downloadSample = async () => {
    await handleBulkRefundSampleFile();
  };
  return (
    <>
      {show_dotted_box ? (
        <Container>
          <IconWrapper>
            <CloudIconNew />
          </IconWrapper>
          <Message>No data received</Message>
          <PickrrButton
            style={{
              width: "200px",
              height: "45px",
              textTransform: "capitalize",
              borderRadius: "8px",
            }}
            color="purple"
            onClick={() => {
              showModal();
            }}
          >
            Upload CSV file
          </PickrrButton>
        </Container>
      ) : (
        <PickrrButton
          color="purple"
          style={{
            width: "107px",
            height: "36px",
            textTransform: "capitalize",
            borderRadius: "8px",
            alignContent: "center",
          }}
          onClick={() => {
            showModal();
          }}
        >
          Upload CSV
        </PickrrButton>
      )}

      <StyledModal
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        centered
        closable={false} /* Disable default close button */
      >
        <ModalContent>
          <UploadArea>
            <StyledUpload
              // beforeUpload={beforeUpload}
              maxCount={1}
              // className="flex flex-row-reverse"
              progress={{
                strokeLinecap: "butt",
                strokeWidth: 3,
                format: (percent) =>
                  percent && `${parseFloat(percent.toFixed(2))}%`,
              }}
              // listType="picture"
              action={`${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/bulk-refund`}
              headers={{ "X-Auth": auth, "User-Token": userToken }}
              accept=".csv"
              onRemove={() => {
                setUploading(false);
              }}
              fileList={fileList}
              onChange={(info) => {
                setUploading(true);
                setFileList(info.fileList);
                if (info?.file?.status === "done") {
                  setUploading(false);
                  setFileList([]);
                  refetch_data();
                  toast.success(
                    `${info?.file?.name} file uploaded successfully`,
                    {
                      icon: ToastSuccessTick,
                    }
                  );
                  handleCancel();
                } else if (info?.file?.status === "error") {
                  setUploading(false);
                  let api_error_msg = info?.file?.response?.message;
                  let message = api_error_msg
                    ? api_error_msg
                    : `${info?.file?.name} file upload failed.`;
                  toast.error(message, {
                    icon: ToastErrorCross,
                  });
                }
              }}
            >
              {!uploading && (
                <>
                  <CloudIcon />
                  <span>Upload CSV file</span>
                </>
              )}
            </StyledUpload>
          </UploadArea>
          <OrText>OR</OrText>
          <DownloadLink onClick={() => downloadSample()}>
            Download template
          </DownloadLink>
        </ModalContent>
      </StyledModal>
    </>
  );
};

export default UploadBox;
