import { DataContext } from "context/DataProvider";
import { CopyIcon, DownIcon, ToastInfoIcon } from "images/icons";
import { toast } from 'react-toastify';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import React, { useContext, useEffect, useState } from "react"
const RechargeModal = ({ walletModalVisible, setWalletModalVisible }: any) => {
  const { walletId, walletBalance, sellerId } = useContext(DataContext);
  const [rechargeAmount, setRechargeAmount] = useState<number>()
  const [showTermsCondtions, setShowTermsConditions] = useState(false);
  const[isCorrectAmount,setIsCorrectAmount]=useState(true);

  const selectAmount = (amount: number) => {
    setRechargeAmount(amount);
  };

  const handleRechargeClick = () => {
    if (rechargeAmount < 200) {
      setIsCorrectAmount(false)
      return
    }
    else {
      setIsCorrectAmount(true)
      window.open(
        `${process.env.REACT_APP_FASTRR_FINANCE}/api/v1/recharge/wallet/initiate?walletId=${walletId}&sellerId=${sellerId}&amount=${rechargeAmount}`,
        '_self'
      );
    }

  }

  const copyToClipboard = (text:string)=>{
    navigator.clipboard.writeText(text); toast.info('Copied to Clipboard',{
      icon:ToastInfoIcon, position: "top-center",
      style: {width:'fit-content'}
    })
  }

  useEffect(() => {
    const handleKeyDown = (event:any) => {
      if (event.key === "Escape") {
        setWalletModalVisible(false); // Call the modal close function
      }
    };

    if (walletModalVisible) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [walletModalVisible, setWalletModalVisible]);

  if (!walletModalVisible) return null; // Hide modal if not open

  return (
    <div>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none">
        <div className="fixed inset-0 bg-black opacity-50" onClick={() => { setWalletModalVisible(false) }} />
        <div className="relative z-50 bg-white rounded-lg max-w-md p-6 h-fit w-1/3">
          <div className="flex justify-between font-bold text-xl">
            <p>
              Recharge your wallet
            </p>
            <div>
              <svg className="h-5 w-5 mt-1 text-checkbox-grey" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" onClick={() => { setWalletModalVisible(false) }}>  <line x1="18" y1="6" x2="6" y2="18" />  <line x1="6" y1="6" x2="18" y2="18" /></svg>
            </div>
          </div>
          <div className="flex justify-between items-center">
          <p className={`mt-2 text-xs`}>Current wallet balance <span className={`${walletBalance > 0 ? 'text-walletColor' : 'text-redWarning'} font-bold text-sm`}>₹{walletBalance}</span></p>
          <div data-tip data-tooltip-id="neft-details" className={` mt-1 text-xs text-purpleText underline flex cursor-pointer`}>Bank details - NEFT Payment {DownIcon(false)}</div>
          </div>
         
          <div className="bg-modalColor h-40 w-full rounded-lg mt-2 pt-3 pl-3 pr-3">
            <p className="text-xs text-modalText">Enter amount to recharge</p>
            <input className="w-full h-9 rounded mt-1 pl-3 text-lg focus:outline-none focus:border-none hover:border-none bg-white border-none" value={rechargeAmount ? rechargeAmount : ""} onChange={(e) => {
              setRechargeAmount(parseInt(e.target.value))
            }}
            />

            <div className="flex-col gap-4">
              <p className="text-xs text-modalText mt-2">Or select amount from below</p>
              <div className="flex gap-2">
                <button className={`rounded-lg px-4 py-2 mt-2 ${rechargeAmount == 200 ? selectedButton :notSelectedButton}`} onClick={() => { selectAmount(200) }}>₹200</button>
                <button className={`rounded-lg px-4 py-2 mt-2 ${rechargeAmount == 500 ? selectedButton : notSelectedButton}`} onClick={() => { selectAmount(500) }}>₹500</button>
                <button className={`rounded-lg px-4 py-2 mt-2 ${rechargeAmount == 1000 ? selectedButton : notSelectedButton}`} onClick={() => { selectAmount(1000) }}>₹1000</button>
                <button className={`rounded-lg px-4 py-2 mt-2 ${rechargeAmount == 2500 ? selectedButton : notSelectedButton}`} onClick={() => { selectAmount(2500) }}>₹2500</button>
                <button className={`rounded-lg px-4 py-2 mt-2 ${rechargeAmount == 5000 ? selectedButton : notSelectedButton}`} onClick={() => { selectAmount(5000) }}>₹5000</button>
              </div>
            </div>
          </div>
          <div className="flex pl-4 pr-3 pt-2 justify-between bg-greyBackground w-full h-10 mt-3 rounded-lg font-semibold">
            <span>Recharge Amount</span>
            <span>₹{rechargeAmount || 0}</span>
          </div>
          {!isCorrectAmount && <div className="text-redWarning ml-2 font-bold">The amount should not be less than ₹200 </div>}
          
          <div className="flex-col justify-center pl-1">
            <button className={`${selectedButton} rounded h-10 w-18 mt-4 ml-32 px-6 py-2`} onClick={handleRechargeClick}>Recharge now</button>
            <div className="flex justify-between">
            <p className={`mt-1 text-xs ml-36 text-purpleText underline flex cursor-pointer`} onClick={() => { setShowTermsConditions(!showTermsCondtions)}}>Terms & Conditions{DownIcon(showTermsCondtions)}</p>
            </div>
            
          </div>
          {showTermsCondtions ? (<div className={`text-sm flex-col gap-4 mt-2`}>
            <ol className="list-disc ml-3">
              <li className="mt-1"> Minimum recharge amount is ₹200</li>
              <li className="mt-1"> Fastrr Credits value is the same as that of Indian Rupee i.e. 1 Fastrr Credit = ₹1</li>
              <li className="mt-1">Fastrr Credits will be used to deduct commission for the orders created through Fastrr checkout on your store.</li>
              <li className="mt-1"> Fastrr Credits are refundable to users. For refund, please connect with us at support@fastrr.com. Please note, the refund of credits earned on wallet recharge will be as per ledger details.</li>
            </ol>
          </div>) : <></>}
          <ReactTooltip id="neft-details" clickable openOnClick variant={"light"} place="bottom-end" opacity={1}>
            <div>
            <p className="my-1 text-md font-semibold">NEFT Payment - Bank details</p>
            Beneficiary Name : Shiprocket Private Limited<br />
            Bank Name : ICICI Bank Limited<br />
            Bank Branch : Punjabi Bagh Branch, 19 West Avenue, New Delhi - 110026<br />
            <div className="flex items-center">Account Number : 015505006304<span onClick={()=>copyToClipboard('015505006304')} className="cursor-pointer ml-1"><CopyIcon/></span></div>
            Account Type : Current account <br />
            <div className="flex items-center">IFSC Code: ICIC0000155<span onClick={()=>{copyToClipboard('ICIC0000155')}} className="cursor-pointer ml-1"><CopyIcon/></span></div>
          </div>
          </ReactTooltip>
          

        </div>
      </div>
    </div>)
}
export default RechargeModal
const selectedButton = "bg-purpleText text-white"
const notSelectedButton="bg-white text-buttonColor"