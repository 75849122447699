import React, { useState, useContext, useEffect } from "react";
import StatisticsBox from "components/UIElements/StatisticsBox";
import GridViewAnalytics from "components/UIElements/StatisticsBox/gridViewAnalytics";
import TableSideCard from "components/UIElements/StatisticsBox/tableSideCard";
import { Row, Col, message, Skeleton, Card } from "antd";
import PieView from "./PieView";
import FunnelView from "./FunnelView";
import GraphView from "./GraphView";
import { Container, SkeletonContainer } from "./styled";
import moment from "moment";
import { CustomDatePicker } from "components/UIElements";
import {
  fetchDashboardData,
  fetchGraphData,
  fetchOrderStatus,
  fetchTrafficAcquisitionData,
  fetchPaymentModeComparisonData,
  fetchExistingCustomer,
  fetchDashboardDiscountData,
  fetchCarouselData
} from "./script";
import { useQuery } from "react-query";
import { DataContext } from "context/DataProvider";
import TableView from "./TableVIew";
import { PAYMENT_MODE_COMPARISON_COLUMNS} from "../Dashboard/TableVIew/util";
import AddressPrefill from "./AddressPrefill";
import Conversion from "./Conversion";
import Discount from "./Discount";
import ExitForm from './ExitForm'
import Carousel from "UiComponents/Carousel";

const Dashboard = () => {
  const { onboardVersion } = useContext(DataContext);
  const [fromDate, setFromDate] = useState<string | null>(
    moment().subtract(6, "days").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState<string | null>(
    moment().format("YYYY-MM-DD")
  );
  const [duration, setDuration] = useState<string>("daily");
  const [dateDiff, setDateDiff] = useState<number | string>(6);
  const [tabPage1, setTabPage1] = useState(1);
  const [tabPage2, setTabPage2] = useState(1);
  const [tabPageSize1, setTabPageSize1] = useState(5);
  const [tabPageSize2, setTabPageSize2] = useState(10);
  const [tabPage3, setTabPage3] = useState(1);
  const [tabPageSize3, setTabPageSize3] = useState(5);
  const [discountPage, setDiscountPage] = useState<number>(1)
  const [carouselDataDisplayed,setCarouselDataDisplayed]=useState<any>([]);


  const [timeDuration, setTimeDuration] = useState("daily");

  const disabledDates = (current: any) => {
    return current > moment();
  };

  const changeDuration = (value: string) => {
    setDuration(value);
  };
  const {
    data: carouselData,
}=useQuery<any>(
    ['carouselData',{}],fetchCarouselData
)
  // const {
  //   data: dashboardData,
  //   isLoading,
  // } = useQuery(
  //   ['dashboardData', { fromDate, toDate, timeDuration}],
  //   fetchDashboardData
  // )

  // const {
  //   data: graphData,
  //   isLoading: isGraphDataLoading,
  // } = useQuery(
  //   ['graphData', { fromDate, toDate, duration}],
  //   fetchGraphData
  // )

  const {
    data: discountData,
    isLoading: isDiscountDataLoading,
  } = useQuery(
    ['discountDataDashboard', { fromDate, toDate, discountPage }],
    fetchDashboardDiscountData
  )


  const { data: orderStatusData, isLoading: orderStatusLoading } = useQuery(
    ["orderStatusData", { fromDate, toDate }],
    fetchOrderStatus
  );

  const {
    data: paymentModeComparisonData,
    isLoading: fetchPaymentModeComparisonDataLoading,
  } = useQuery(
    ["paymentModeComparison", { fromDate, toDate, tabPage2, tabPageSize2 }],
    fetchPaymentModeComparisonData
  );

  const totalSales = {
    fastrrOrderCount: orderStatusData?.fastrrOrderGmv,
    channelOrderCount: orderStatusData?.channelOrderGmv,
    otherOrderCount: orderStatusData?.otherOrderGmv,
    total: orderStatusData?.totalOrderGmv
  };

  const totalOrders = {
    fastrrOrderCount: orderStatusData?.fastrrOrderCount,
    channelOrderCount: orderStatusData?.channelOrderCount,
    otherOrderCount: orderStatusData?.otherOrderCount,
    total: orderStatusData?.totalOrderCount
  };
  useEffect(() => {
    const transformData = (data: any) => {
      return data?.map((item: any) => ({
              id: item.bannerId,
              backgroundImage: item.backgroundImage,
              ctaImage: item.bannerImage,
              heading: item.headerText,
              text: item.text,
              ctaText: item.ctaText,
              subheaderText: item.subheaderText,
              headerColor: item.headerColor,
              redirectLink: item.ctaLink,
              ctaButtonColor: item.ctaButtonColor,
              active: item.active,
              backgroundCtaButtonColor: item.backgroundCtaButtonColor
            }));
          };
      if (transformData(carouselData)) {
          setCarouselDataDisplayed(transformData(carouselData));
      }
  
  }, [carouselData]);

  return (
    <>
      {carouselDataDisplayed?.length > 0 &&<div style={{marginBottom:'12px'}}>
        <Carousel banners={carouselDataDisplayed}/>
      </div>
      }
     
      <div
        style={{
          textAlign: "right",
          padding: "0px 0px 15px 0px",
        }}
      >
        <CustomDatePicker
          allowClear={false}
          onChange={(dates: Array<moment.Moment>) => {
            if (dates[1].diff(dates[0], "days") > 60) {
              message.error(
                "Date range can not exceed 30 days. Please select date range less than or equal to 60   days only.",
                1.5
              );
            } else {
              dates && dates[0]
                ? setFromDate(dates[0].format("YYYY-MM-DD"))
                : setFromDate(null);
              dates && dates[1]
                ? setToDate(dates[1].format("YYYY-MM-DD"))
                : setToDate(null);
            }
            setDateDiff(dates[1].diff(dates[0], "days"));
            if (dates[1].diff(dates[0], "days") === 0) {
              setDuration("hourly");
            } else {
              setDuration("daily");
            }
          }}
          ranges={{
            "Today": [moment(), moment()],
            "Yesterday": [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last 7 Days": [moment().subtract(6, "d"), moment()],
            "This Month": [
              moment().startOf("month"),
              moment().subtract(1, "days"),
            ],

            "Last 30 Days": [
              moment().subtract(30, "days"),
              moment().subtract(1, "days"),
            ],
          }}
          defaultValue={[
            moment(fromDate, "YYYY-MM-DD"),
            moment(toDate, "YYYY-MM-DD"),
          ]}
          disabledDate={disabledDates}
        />
      </div>
      {orderStatusLoading ? (
        <SkeletonContainer>
          <Skeleton active paragraph={{ rows: 15 }} />
        </SkeletonContainer>
      ) : (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Container>
              <StatisticsBox
                heading="Total Sales"
                // stats={dashboardData?.overallStats[0]}
                isLoading={orderStatusLoading}
                fromDate={fromDate}
                toDate={toDate}
                // totalOrders={dashboardData?.overallStats[1]?.curValue}
                data={totalSales}
                showRupee={true}
              />
              <StatisticsBox
                heading="Total Orders"
                // stats={dashboardData?.overallStats[1]}
                isLoading={orderStatusLoading}
                fromDate={fromDate}
                toDate={toDate}
                // totalOrders={dashboardData?.overallStats[1]?.curValue}
                data={totalOrders}
                showRupee={false}
              />
              {/* {onboardVersion && onboardVersion !== 'v3' &&  */}
              <GridViewAnalytics
                heading="Address Prefill %"
                stats={orderStatusData?.otherPrepaidPercent}
                isLoading={orderStatusLoading}
                fromDate={fromDate}
                toDate={toDate}
                totalOrders={orderStatusData?.fastrrPrepaidPercent}
                fastrrPrepiadOrder={orderStatusData?.fastrrPrepaidPercent}
                prepaidOrder={orderStatusData?.totalPrepaidPercent}
              />
              {/* } */}
              {/* <StatisticsBox 
    heading="Prepaid% : COD%"
    stats={dashboardData?.overallStats[3]}
    isLoading={isLoading}
    fromDate={fromDate}
    toDate={toDate}
    totalOrders={dashboardData?.overallStats[1]?.curValue}
  />
  <StatisticsBox 
    heading="Cart Abandonment Rate"
    stats={dashboardData?.overallStats[4]}
    isLoading={isLoading}
    fromDate={fromDate}
    toDate={toDate}
    totalOrders={dashboardData?.overallStats[1]?.curValue}
  /> */}
            </Container>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Conversion
              fromDate={fromDate}
              toDate={toDate}
              dateDiff={dateDiff}
            />
          </Col>
          <Col xs={16} sm={16} md={16} lg={16} xl={16}>
            <TableView
              tabTitle1={"Traffic Acquisition"}
              tabTitle2={"Payment Mode Comparison"}
              columnsData2={paymentModeComparisonData?.paymentModeResponse}
              columns2={PAYMENT_MODE_COMPARISON_COLUMNS}
              tabPage2={tabPage2}
              setTabPage2={setTabPage2}
              tabPageSize2={tabPageSize2}
              setTabPageSize2={setTabPageSize2}
              tabTotal2={paymentModeComparisonData?.totalPaymentModeData}
              expandable={false}
              showRight={false}
              fromDate={fromDate}
              toDate={toDate}
            />
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <AddressPrefill fromDate={fromDate} toDate={toDate} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Discount
              discountData = {discountData}
              discountPage = {discountPage}
              setDiscountPage = {setDiscountPage}
              isDiscountDataLoading = {isDiscountDataLoading}
              fromDate={fromDate}
              toDate={toDate}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <ExitForm 
              fromDate = {fromDate}
              toDate = {toDate}
              />
            </Col>

          {/* <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <TableSideCard 
                    heading="Address Prefill %"
                    stats={dashboardData?.overallStats[2]}
                    isLoading={isLoading}
                    fromDate={fromDate}
                    toDate={toDate}
                    totalOrders={dashboardData?.overallStats[1]?.curValue}
                  />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <TableSideCard 
                    heading="Address Prefill %"
                    stats={dashboardData?.overallStats[2]}
                    isLoading={isLoading}
                    fromDate={fromDate}
                    toDate={toDate}
                    totalOrders={dashboardData?.overallStats[1]?.curValue}
                  />
              </Col>
            </Row>
             
            </Col> */}
          {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <TableView 
                tabTitle1 = {'Traffic Acquisition'}
                tabTitle2 = {'Payment Mode Comparison'}
            />
            </Col> */}
          {/* } */}
          {onboardVersion && onboardVersion !== "v3" && (
            <>
              {/* <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <PieView
                  heading="Cart Abandonment Retargeting"
                  total={dashboardData?.cartAbandonmentRetargeting?.curTotal}
                  stats={[
                    {
                      title:"Conversion %", 
                      value: `${dashboardData?.cartAbandonmentRetargeting?.details?.Conversion}%`
                    },
                    {
                      title:"Sales", 
                      value: `₹${(dashboardData?.cartAbandonmentRetargeting?.details?.sales)?.toLocaleString('en-IN')}`
                    }
                  ]}
                  details={[
                    {
                      title:"Unsuccesful Retargeting", 
                      value: dashboardData?.cartAbandonmentRetargeting?.details?.unsuccessful_retargetting <= 0 ?
                      0.0001 :
                      dashboardData?.cartAbandonmentRetargeting?.details?.unsuccessful_retargetting,
                      tooltip: "Abandoned carts for which SMS/WA for resuming checkout were sent but were not recovered."
                    },
                    {
                      title:"Orders Through Retargeting", 
                      value: dashboardData?.cartAbandonmentRetargeting?.details?.orders_through_retargetting
                    }
                  ]}
                />
              </Col> */}
              {/* <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <PieView
                  heading="RTO Breach"
                  total={dashboardData?.rto?.curTotal}
                  stats={[
                    {title:"Mismatch %", value: `${dashboardData?.rto?.details?.mismatched}%`},
                    //{title:"Refund", value: `₹${dashboardData?.rto?.details?.refund}`}
                  ]}
                  details={[
                    {
                      title:"Incorrect Prediction", 
                      value: dashboardData?.rto?.details?.incorrect_prediction <= 0 ? 
                      0.0001 : 
                      dashboardData?.rto?.details?.incorrect_prediction,
                      tooltip: "Orders that were RTO despite being predicted to have low RTO risk."
                    },
                    {
                      title:"Correct Prediction", 
                      value: dashboardData?.rto?.details?.correct_prediction,
                      tooltip: "Orders for which RTO risk was predicted to be low and were delivered."
                    }
                  ]}
                />
              </Col> */}
              {/* <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <PieView
                  heading="EDD Breach"
                  total={dashboardData?.edd?.curTotal}
                  stats={[
                    {title:"Breach Orders %", value: `${dashboardData?.edd?.details?.breached_orders}%`},
                    //{title:"Refund", value: `₹${dashboardData?.edd?.details?.refund}`}
                  ]}
                  details={[
                    {
                    title:"Pickup Breach", 
                    value: dashboardData?.edd?.details?.pickup_breach <= 0 ?
                    0.0001 :
                    dashboardData?.edd?.details?.pickup_breach
                    },
                    {title:"Breach", value: dashboardData?.edd?.details?.breach},
                    {title:"No Breach", value: dashboardData?.edd?.details?.no_breach},

                  ]}
                />
              </Col> */}
            </>
          )}
        </Row>
      )}
    </>
  );
};

export default Dashboard;
