import React, { useContext, useEffect, useState } from "react"
import { AbandonedLogo, CollapseSRLogo, DashboardLogo, DiscountLogo, FastrrLoginIcon, FinanceLogo, HelpSupport, HomeLogo, OrdersLogo, PaymentLogo, SRlogo, SettingsLogo, ToastErrorCross, ToastWarningExclamation, TopBarImage, UserMangementIcon,ReportsIcon } from "images/icons";
import { Link } from "react-router-dom";
import NavBar from "UiComponents/Navbar/NavBar";
import { DataContext } from "context/DataProvider";
import PricingModalNew from "components/CommonComponents/PricingModalNew";
import PricingModal from "components/CommonComponents/PricingModal";
import { useQuery } from "react-query";
import RechargeWalletModal from "components/CommonComponents/RechargeWalletModal";
import { is_local, routePermission } from "utils/functions";
import SrLogin from "pages/SrLogin";
import { fetchRatesDetail, fetchSrAccounts } from "./script";
import SideBox from "./SideBox";
import { DataBar, accordianType } from "./data";
import RechargeModal from "UiComponents/Modals/RechargeModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { ErrorDiv } from "./style";

interface WalletDetails {
    days_left_for_redirection: number;
  }

const contextClass = {
    success: "bg-toastSuccessBackground border border-toastSuccessBorder",
    error: "bg-toastErrorBackground border border-toastErrorBorder",
    info: "bg-toastInfoBackground border border-toastInfoBorder",
    warning: "bg-toastWarningBackground border border-toastWarningBorder",
    default: "bg-toastInfoBackground border border-toastInfoBorder",
  };
  
  const commonToastClass = "rounded-md text-black flex fit-content";

const SideBar = ({ header, children }: any) => {
    const [walletModalVisible, setWalletModalVisible] = useState<boolean>(false)
    const [isViewPricingModalVisible, setIsViewPricingModalVisible] = useState<boolean>(false);
    const [isPricingModalVisible, setIsPricingModalVisible] = useState<boolean>(false);
    const [srDomainList, setSrDomainList] = useState<any>([]);
    const [srLoginError, setSrLoginError] = useState<string>();
    const [cid,setCid] = useState();
    const navigate = useNavigate();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get('token');
    const uid = urlSearchParams.get('uid');
    const {
        onboardVersion,
        isRateApproved,
        walletBalance,
        fetchingWalletDetails,
        walletDetails,
        walletError,
        sellerDomain,
        channelPlatForm,
        platFormDetails,
        hideSideBar,
        setHideSideBar,
        displayAccordion,
        setdisplayAccordion,
        defaultSelectedKey,
        setDefaultSelectedKey,
        displayHover,
        walletDataLoading,
        sellerConfig,
        srArrayList,
        setSrArrayList
    } = useContext(DataContext);
   
    const showDashboard = ((!walletError || channelPlatForm !== 'custom') || is_local() || fetchingWalletDetails) && !token
    const domainName = sellerDomain;
    const loggedInFromAdmin = localStorage.getItem("loggedInFromAdmin"); //have to remove this


    const isToleranceBreached = walletDetails?.wallet_balance < walletDetails?.current_tolerance;
    const isRedirectionUpcoming = walletDetails?.days_left_for_redirection<=3;

    const { data: ratesData, isLoading, isError } = useQuery(
        ['rateDetails', { domainName }],
        fetchRatesDetail,
        { enabled: !token }
    );

    const { data: fetchSrAccountsData, isLoading: fetchingSRAccounts } = useQuery(
        ['fetchSrAccounts', { token }],
        fetchSrAccounts
    )
    useEffect(() => {
        if (fetchSrAccountsData?.error || fetchSrAccountsData?.errors) {
            setSrLoginError("Something went wrong")
        }
        else if (!fetchSrAccountsData?.error && fetchSrAccountsData && !fetchSrAccountsData?.errors) {
            setCid(fetchSrAccountsData?.company_id)
            localStorage.setItem('cid',fetchSrAccountsData?.company_id)
            setSrDomainList(fetchSrAccountsData?.account_info_list)
            localStorage.setItem('user_email',fetchSrAccountsData?.user_email)
            localStorage.setItem('user_name',fetchSrAccountsData?.user_name)
            localStorage.setItem('user_phone',fetchSrAccountsData?.mobile)
            // localStorage.setItem('sr_account_list',fetchSrAccountsData?.account_info_list)
        }

    }, [fetchSrAccountsData])

    useEffect(() => {
        if ((ratesData?.COD?.length > 0 || ratesData?.prepaid?.length > 0)) {
            setIsPricingModalVisible(true)
        } else {
            setIsPricingModalVisible(false)
        }

    }, [ratesData])

    const isOnboarding = localStorage.getItem('isOnboarding') ?? null;
    const onboardingType = localStorage.getItem('onboardingType') ?? null;

    let showOtherTabs = false;
    if(
        sellerConfig?.is_native_shopify_functions_checkout && 
        !sellerConfig?.platform_details?.shopify?.storefront_access_token
    ){
        showOtherTabs = false;
    }else {
        showOtherTabs = true;
    }
    
    const data: DataBar[] = [
        ...((localStorage.getItem('isOnboarding')) ? [{
            name:'Home',
            icon:<HomeLogo color={(defaultSelectedKey === 'Home'|| displayHover=='Home')?'black':'white'}/>,
            collapse:false,
            path:'/home'
        }]:[]),
        ...(routePermission('dashboard') && showOtherTabs ? [
            {
                name: 'Dashboard',
                icon: <DashboardLogo color={(defaultSelectedKey === 'Dashboard' || displayHover === 'Dashboard') ? 'black' : 'white'} />,
                collapse: false,
                path: '/dashboard',
            }
        ] : []),
        ...(
            (!platFormDetails?.custom?.login && routePermission('orders')) && showOtherTabs ? [{
            name: 'Orders',
            icon: <OrdersLogo color={(defaultSelectedKey === 'Orders' || displayHover === 'Orders') ? 'black' : 'white'} />,
            collapse: true,
            path: '',
            views: [
                {
                    name: 'Orders',
                    icon: "",
                    path: '/orders' 
                },
                // {...(loggedInFromAdmin && {
                {
                    name: 'Pending Orders',
                    icon: "",
                    path: '/pending-orders'  
                }
            ]
        }]: [])
        ,
        ...(
            (!platFormDetails?.custom?.login && routePermission('finance') && showOtherTabs)
            ? [{
            name: 'Finance',
            icon: <FinanceLogo color={(defaultSelectedKey === 'Finance' || displayHover === 'Finance') ? 'black' : 'white'} />,
            collapse: true,
            path: '',
            views: [
                {
                    name: 'Customer Finance',
                    icon: "",
                    path: '/finance'
                },
                {
                    name: "Refunds",
                    icon: '',
                    path: '/refunds'
                },
                {
                    name: 'Wallet',
                    icon: '',
                    path: '/wallet',
                },
                {
                    name: 'Settlement History',
                    icon: '',
                    path: '/settlementhistory',

                },
            ]
        }] : []),
        ...(routePermission('abandoned-cart') && showOtherTabs ? 
            [{
                name: 'Abandoned Carts',
                icon: <AbandonedLogo color={(defaultSelectedKey === 'Abandoned Carts' || displayHover === 'Abandoned Carts') ? 'black' : 'white'} />,
                collapse: false,
                path: '/abandoned-cart'
            }]
        : []),
        ...(routePermission('dashboard') && showOtherTabs ? [{
            name:'Fastrr Login',
            icon:<FastrrLoginIcon color={(defaultSelectedKey==='Fastrr Login' || displayHover==="Fastrr Login")?'black':'white'}/>,
            collapse:false,
            path:'/fastrrlogin'
        }] : []),
        ...(
            ((channelPlatForm !== 'custom' || (!platFormDetails?.custom?.login && channelPlatForm === 'custom')) && routePermission('settings/general-settings')) && showOtherTabs ? [{
            name: 'Settings',
            icon: <SettingsLogo color={(defaultSelectedKey === 'Settings' || displayHover === 'Settings') ? 'black' : 'white'} />,
            collapse: false,
            path: '/settings/general-settings',
            disabled: localStorage.getItem('isOnboarding') && onboardingType === "NEW" ? true : false
        }] :[]),
        ...(
        (channelPlatForm === 'custom' && platFormDetails?.custom?.login) ? [{
            name: 'User Data',
            icon: <SettingsLogo color={(defaultSelectedKey === 'User Data' || displayHover === 'User Data') ? 'black' : 'white'} />,
            collapse: false,
            path: '/userdata',
        }] : []), 
        ...(routePermission('user-management') && showOtherTabs ? [
            {
                name: 'User Management',
                icon: <UserMangementIcon color={(defaultSelectedKey === 'User Management' || displayHover === 'User Management') ? 'black' : 'white'} />,
                collapse: false,
                path: '/user-management',
                disabled: localStorage.getItem('isOnboarding') && onboardingType === "NEW" ? true : false
            }
        ] : []),
        ...(routePermission('dashboard')  && showOtherTabs ? [
            {
                name: 'Reports',
                icon: <ReportsIcon color={(defaultSelectedKey === 'Reports' || displayHover === 'Reports') ? 'black' : 'white'} />,
                collapse: false,
                path: '/reports',
            }
        ] : []),
        ...(sellerConfig?.is_native_shopify_functions_checkout ? [
            {
                name: 'SmartCOD',
                icon: <DashboardLogo color={(defaultSelectedKey === 'SmartCOD' || displayHover === 'SmartCOD') ? 'black' : 'white'} />,
                collapse: false,
                path: '/charge-rule',
            },
        ] : []),   
    ]


    const handleAccordionClick = (name: string) => {
        let temp: accordianType = {}
        temp[name] = !displayAccordion[name]
        setdisplayAccordion({ ...displayAccordion, ...temp })
    }

    const handleMouseLeave = () => {
        setHideSideBar(true)
    }

    const handleMouseEnter = () => {
        setHideSideBar(false)
    }

    return (<>
        {showDashboard ? <div className="flex flex-row h-screen z-50">
            <div>
                <div className={`${topContainerClass} ${hideSideBar ? 'w-24' : `w-60`}`} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
                    <div>
                        <div className={`flex justify-between pl-5 pr-7 ${hideSideBar ? '-ml-1 pt-6 ' : "pt-5 ml-4"}`}>
                            <Link to='/dashboard'>
                                {hideSideBar ? <><CollapseSRLogo /></> : <SRlogo />}
                            </Link>
                        </div>
                        <div className="mt-6">
                            <div className={`${hideSideBar ? 'mt-4' : 'mt-10'}`}>
                                <SideBox data={data} hideSideBar={hideSideBar} setDefaultSelectedKey={setDefaultSelectedKey} defaultSelectedKey={defaultSelectedKey} displayAccordion={displayAccordion} handleAccordionClick={handleAccordionClick} />
                            </div>
                        </div>
                        <div>

                        </div>
                    </div>

                </div>
            </div>

            <div className="flex flex-col min-h-screen" style={{backgroundColor:"#F1F5F9"}}>
                {header ? <>
                { header?.title?.toUpperCase()=='DASHBOARD' &&  <div className="flex justify-between w-full h-12 items-center bg-bannerBg p-2.5">
                        <div className={`flex items-center ml-28` }>
                            <h3>
                                See how Shiprocket checkout simplifies purchase journey for your customers.
                            </h3>
                            <div
                                className="border-2 ml-1.5 cursor-pointer border-reserveColor rounded text-reserveColor px-1.5 font-semibold"
                                onClick={() => {
                                    window.open("https://calendly.com/checkoutsupport/shiprocket-checkout-training-1", "_blank")
                                }}
                            >
                                Reserve your spot now
                            </div>
                            </div>

                            <div className="ms-68">
                                <span style={{color:'#263F97',fontWeight:'700'}}>Support Email: </span>
                                <span style={{marginTop:'-5px'}}>checkoutsupport@shiprocket.com</span></div>
                            </div>
                        }
                    <NavBar title={header?.title} setIsViewPricingModalVisible={setIsViewPricingModalVisible} setWalletModalVisible={setWalletModalVisible} walletBalance={walletBalance} isCollapse={hideSideBar} walletDataLoading={walletDataLoading} walletDetails={walletDetails} platFormDetails={platFormDetails} sellerConfig= {sellerConfig} />
                    {
                        (!fetchingWalletDetails &&
                            !platFormDetails?.custom?.login &&
                            isToleranceBreached && walletDetails?.days_left_for_redirection<=15) &&
                        (walletDetails?.days_left_for_redirection > 0 ?
                            (<ErrorDiv bgColor={isRedirectionUpcoming && '#FFF2F2'} borderColor={isRedirectionUpcoming && '#FFCCC7'}>
                                {<span style={{ marginRight: '10px' }}>{isRedirectionUpcoming?<ToastErrorCross />:<ToastWarningExclamation /> }</span>}
                                Your wallet balance is insufficient. Please recharge within {walletDetails?.days_left_for_redirection} day(s) to avoid redirection to native checkout.
                            </ErrorDiv>) :
                            (<ErrorDiv bgColor={'#FFF2F2'} borderColor={'#FFCCC7'}>
                                <span style={{ marginRight: '10px' }}><ToastErrorCross /></span>
                                Your account is on hold. Please recharge immediately to continue using Shiprocket Checkout services.
                            </ErrorDiv>)
                        )
                    }
                </>
                    : <div></div>
                }

                {((!fetchingWalletDetails && !(localStorage.getItem('isOnboarding'))) || ((localStorage.getItem('isOnboarding')) && !uid && !token)) && <div className={`bg-background  pr-4 py-4 w-svw h-fit  ease-in-out duration-300 pl-28`}>
                    <div className="pr-2">{children}</div>
                </div>
                }
            </div>
            {(walletModalVisible && !platFormDetails?.custom?.login) &&
                <RechargeModal walletModalVisible = {walletModalVisible} setWalletModalVisible={setWalletModalVisible}/>
            }


        </div> : <div>
            {token ? 
            <SrLogin 
                srAccountList={srDomainList} 
                srLoginError={srLoginError} 
                cid={cid} 
                fetchingSRAccounts={fetchingSRAccounts}
            /> :
                <div style={{ display: 'flex', width: '100%', color: 'red', fontSize: '24px', fontWeight: 600, marginTop: '20px', justifyContent: 'center' }}>
                    An error occurred! Please try again later.
                </div>
            }
        </div>
        }
        {(ratesData && (!platFormDetails?.custom?.login)) &&
            <PricingModal
                isPricingModalVisible={isPricingModalVisible}
                setIsPricingModalVisible={setIsPricingModalVisible}
                ratesData={ratesData}
                isLoading={isLoading}
                isError={isError}
            />
        }

        {(isViewPricingModalVisible && !platFormDetails?.custom?.login) &&
            <PricingModalNew
                isViewPricingModalVisible={isViewPricingModalVisible}
                setIsViewPricingModalVisible={setIsViewPricingModalVisible}
            />
        }
        <ToastContainer toastClassName={(context) => `${commonToastClass} ${contextClass[context?.type || "default"]}`} hideProgressBar closeButton={false} />

    </>

    )
}
export default SideBar

const topContainerClass = 'h-full fixed flex flex-col justify-between pb-8 bg-secondary text-greytext ease-in-out duration-300 z-50';
