import { fetchSellerCheckoutConfig } from 'components/Settings/Discount/functions';
import { createContext, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import FastrrLayout from 'components/MasterLayout';
import { GetAuthToken, getCookieValue, getUserDetails } from 'globalQuries';
import axios from 'axios';
import { notification } from 'antd';
import { fetchWalletDetails } from './script';
import {sessionExpired} from '../../src/utils/functions';
import { useNavigate } from 'react-router-dom';
// import axiosInstance from '../../src/HTTP/Http';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const navigate = useNavigate();
  const [onboardVersion, setOnboardVersion] = useState('v3');
  const [hideSideBar,setHideSideBar]=useState(true)
  const [displayAccordion, setdisplayAccordion] = useState({})
  const [displayHover,setDisplayHover]=useState('')
  const [shopifyUrl, setShopifyUrl] = useState("")
  const [accessCode, setAccessCode] = useState("")
  const [approveRequest, setApproveRequest] = useState(false)
  const[shareRequest,setShareRequest]=useState(false)
  
  const [channelPlatForm, setChannelPlatForm] = useState('shopify');
  const [sellerId , setSellerId] = useState('')
  const [razorpayAuthorized , setRazorpayAuthorized] = useState(false)
  const [razorpayButtonActive , setRazorpayButtonActive] = useState(false)
  const [walletBalance, setWalletBalance] = useState(0)
   const [walletId, setWalletId] = useState(null)
  const [fetchingWalletDetails, setFetchingWalletDetails] = useState(true)
  const [isRateApproved, setIsRateApproved] = useState(false)
  const [walletDetails,setWalletDetails] = useState({})
  const [IpAddress, setIpAddress] = useState('')
  const [walletHeader,setWalletHeader] = useState('')
  const [sellerDomain, setSellerDomain] =  useState();
  const [channel, setChannel] = useState('');
  const [showSessions,setShowSessions]=useState()
  const [platFormDetails, setPlatFormDetails] = useState('');
  const [panelShow, setShowPanel] = useState([]);
  const [sessionCreate, setSessionCreate] = useState();
  const [srArrayList,setSrArrayList] = useState([])

  const [defaultSelectedKey, setDefaultSelectedKey] = useState('');
  const [sellerConfig, setSellerConfig] = useState({})
  const ShiprocketToken = localStorage.getItem("ShiprocketToken")
  // const sr_account_list=localStorage.getItem('sr_account_list')
  const urlSearchParams = new URLSearchParams(window.location.search);  
  const token = urlSearchParams.get('token');
  const isOnboarding = localStorage.getItem('isOnboarding') ?? null;
  const onboardingType = localStorage.getItem('onboardingType') ?? null;

  const createPendingOnboarding = () => {
  try {
    localStorage.setItem('isOnboarding', 'true')
    localStorage.setItem('onboardingType', 'DONE')
    localStorage.setItem('panel',	`"[settings/general-settings, user-management, orders, finance, dashboard, abandoned-cart]"`)
    localStorage.removeItem('loggedInFromAdmin')
    localStorage.setItem('utm_medium', urlSearchParams.get('utm_medium'))
  } catch (e) {}
}

useEffect(()=>{
  console.log("THE CURRENT ACCORDION CLICK IS ",defaultSelectedKey)
},[defaultSelectedKey])
  // const {
  //     data: configData,
  //     isLoading,
  //     isSuccess,
  //   } = useQuery('checkingV3', fetchSellerCheckoutConfig);

  const auth = GetAuthToken();

  const [state, setState] = useState();

  // useEffect(() => {
  //   setTimeout(() => {
  //     if(GetAuthToken()) setState(true)
  //   }, 1000)
  // }, [])

  const {data: WalletData, error : walletError, isFetching: walletDataLoading } = useQuery(
      ['WalletData', sellerId, ShiprocketToken],
      fetchWalletDetails,
      {
          enabled: !!sellerId 
      }
  );
  useEffect(()=>{
    if(WalletData?.data?.result){
      setFetchingWalletDetails(false)
      setWalletDetails(WalletData?.data?.result)
      if(WalletData?.data?.result?.is_active){
        setWalletBalance(WalletData?.data?.result?.wallet_balance)
      }
      if(WalletData?.data?.result?.is_active && WalletData?.data?.result?.id){
        setWalletId(WalletData?.data?.result?.id)
      }
    }
    if(WalletData?.config?.headers){
      const srwalletheader = WalletData?.headers?.['sr-wallet-details']
      if(srwalletheader){
        setWalletHeader(srwalletheader)
      }
    }
    if(walletError){
      setFetchingWalletDetails(false)
    }
  },[WalletData,walletError])

  const fetchSellerCheckoutConfig = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);    
    const uid = urlSearchParams.get('uid');
    const auth = GetAuthToken();  
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PICKRR_CHECKOUT}/seller/config/`,
        {
          headers: {
            Authorization: auth ? auth : uid,
          },
        }
      );


      if(response?.data?.data){
        setSellerConfig(response?.data?.data)
        if (response?.data.data?.account_status && !response?.data?.data?.account_status?.toUpperCase()?.includes('LIVE')){
          createPendingOnboarding()
        }
        else {
          localStorage.removeItem('isOnboarding')
          localStorage.removeItem('onboardingType')
        }
      }
      
      if (response?.data?.data?.onboard_version) {
        setOnboardVersion(response?.data?.data?.onboard_version);
        setChannelPlatForm(response?.data?.data?.platform_details?.channel);
        setPlatFormDetails(response?.data?.data?.platform_details);
      }
      if(response?.data?.data?._id){
        setSellerId(response?.data?.data?._id);
      }
      if(response?.data?.data?.razorpay_authorized){
        setRazorpayAuthorized(response?.data?.data?.razorpay_authorized);
      }
      if(response?.data?.data?.razorpay_button_active){
        setRazorpayButtonActive(response?.data?.data?.razorpay_button_active);
      }
      if(response?.data?.data?.rates_approval_stats){
        setIsRateApproved(response?.data?.data?.rates_approval_stats?.rates_approved)
      }
      if(response?.data?.data?.requester_ip){
        setIpAddress(response?.data?.data?.requester_ip)
      }

      if(response?.data?.data?.domain_name){
        setSellerDomain(response?.data?.data?.domain_name);
      }
      if(response.data?.data?.platform_details?.channel) {
        setChannel(response.data?.data?.platform_details?.channel)
      }
      if (response.data?.data?.shopify_plus_user){
        setShowSessions(response.data?.data?.shopify_plus_user)
      }
      return response?.data?.data?.onboard_version;
    } catch (error) {
      notification.error({
        message: error.message,
      });
      sessionExpired();
    }
  };

  // useEffect(() => {
  //   fetchSellerCheckoutConfig();
  // }, [state]);

  useEffect(() => {
    if(
      (!token && !isOnboarding) || //normal flow
      localStorage.getItem('loggedInFromAdmin') || //admin flow
      (!token && isOnboarding && onboardingType === "DONE")) //continued onboarding
      { 
        fetchSellerCheckoutConfig();
      }
  }, [token]);

  // useEffect(() => {
  //     setOnBoardVersion(configData)
  // }, [])

  return (
    <DataContext.Provider
      value={{
        onboardVersion,
        channelPlatForm,
        sellerId,
        razorpayAuthorized,
        razorpayButtonActive,
        walletBalance,
        walletId,
        fetchingWalletDetails,
        walletDetails,
        walletError,
        isRateApproved,
        IpAddress,
        walletHeader,
        sellerDomain,
        channel, 
        platFormDetails,
        showSessions,
        panelShow, 
        setShowPanel, 
        sessionCreate, 
        setSessionCreate,
        hideSideBar,
        setHideSideBar,
        displayAccordion,
        setdisplayAccordion, 
        defaultSelectedKey, 
        setDefaultSelectedKey,
        displayHover,
        setDisplayHover,
        sellerConfig,
        walletDataLoading,
        setSrArrayList,
        srArrayList,
        shopifyUrl,
        setShopifyUrl,
        approveRequest,
        setApproveRequest,
        accessCode,
        setAccessCode,
        shareRequest,
        setShareRequest
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
