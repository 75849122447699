import { Form, Col, Menu, Row } from "antd";
import React, { useEffect } from "react";
import CustomerCriteria from "./CustomerCriteria";
import MinimumCartTotalCriteria from "./MinimumCartTotal";
import ProductCriteria from "./ProductCriteria";
import QuantityCriteria from "./QuantityCriteria";
import { CriteriaContent, NewContainer } from "./styled";
import UsageCriteria from "./UsageCriteria";
import BankOffer from "./BankOffer";
import MaximumCart from './MaximumCart'
import UtmCriteria from "./UtmCriteria";
import ApplicableModes from "./ApplicableModes";

const CriteriaTypes = ({
  value,
  setValue,
  productList,
  customerType,
  setCustomerType,
  setProductList,
  discountType,
  handleSubmit,
  currentMenu,
  setCurrentMenu,
  selectedPhoneNumbers,
  setSelectedPhoneNumbers,
  setSelectTypes,
  selectTypes,
  discountMethod,
  sellerConfigValues,
  criteriaUpdate,
  setCriteriaUpdate,
  cardDetails, 
  setCardDetails,
  form,
  selectedPinCode,
  setSelectedPinCode,
  tagsString,
  setTagsString,
  setOrderCount,
  orderCount
}: any) => {

  const props = {
    criteriaUpdate,
    setCriteriaUpdate,
    form,
    discountType,
    selectTypes
  }



  const SelectedCriteriaType: any = {
    1: (
      <ProductCriteria
        value={value}
        setValue={setValue}
        productList={productList}
        setProductList={setProductList}
        setSelectTypes = {setSelectTypes}
        selectTypes = {selectTypes}
        sellerConfigValues = {sellerConfigValues}
        tagsString={tagsString}
        setTagsString={setTagsString}
      />
    ),
    2: <MinimumCartTotalCriteria {...props}/>,
    3: (
      <CustomerCriteria
        type={discountType}
        selectedPhoneNumbers={selectedPhoneNumbers}
        setSelectedPhoneNumbers={setSelectedPhoneNumbers}
        customerType={customerType}
        setCustomerType={setCustomerType}
        selectedPinCode={selectedPinCode}
        setSelectedPinCode={setSelectedPinCode}
        tagsString={tagsString}
        setTagsString={setTagsString}
        setOrderCount={setOrderCount}
        orderCount={orderCount}
      />
    ),
    4: <UsageCriteria {...props}/>,
    5: <QuantityCriteria {...props}/>,
    6 : <UtmCriteria {...props}/>,
    7: <BankOffer 
        cardDetails = {cardDetails} 
        setCardDetails = {setCardDetails}
    />,
    8: <MaximumCart {...props}/>,
    9: <ApplicableModes {...props} />
  };

  useEffect(() => {
    discountType === "amtCart" ||
    discountType === "bxgy" ||
    discountType === "bundle" ||
    discountType === "aftersell"
      ? setCurrentMenu("2")
      : setCurrentMenu("1");


    if(discountType === "bankOffer"){
      setCurrentMenu("7")
    }



  }, []);
  const notShowFlag=(tagsString.length==0 && productList.length==0)&&( discountType === "prdAmt" ||discountType==='tiered')

  console.log('DiscountTypeCheck', discountType)

  // console.log("currentMenu", typeof currentMenu);
  return (
    <>
      <Row>
        <Col xl={6} lg={6} md={24} sm={24} xs={24}>
          <NewContainer>
            <Form.Item name="discountCriteria" initialValue={currentMenu}>
              <Menu
                mode="inline"
                onSelect={(e) => {
                  handleSubmit(e.key);
                }}
                selectedKeys={currentMenu}
                defaultSelectedKeys={currentMenu}
              >
                {!(
                  discountType === "amtCart" ||
                  discountType === "bxgy" ||
                  discountType === "bundle" ||
                  discountType === "aftersell"
                ) && <Menu.Item key={1}>Product</Menu.Item>}
                <Menu.Item disabled={notShowFlag} key={2}>Minimum Cart Total</Menu.Item>
                <Menu.Item  disabled={notShowFlag} key={3}>Customer</Menu.Item>
                {discountMethod === "couponCode" && (
                  <Menu.Item key={4}>Usage</Menu.Item>
                )}
                {!(discountType === "bxgy" || discountType === "bundle") && (
                  <Menu.Item key={5} disabled={notShowFlag}>Quantity</Menu.Item>
                )}
                <Menu.Item key={6}  disabled={notShowFlag}>UTM Parameters</Menu.Item>
                {discountType  === "bankOffer"  && 
                   <Menu.Item key={7} >Payment Method</Menu.Item>
                }
                {(discountType  === "bankOffer" || discountType === 'freebie')  && 
                   <Menu.Item key={8}>Maximum Cart Total</Menu.Item>
                }
                {discountType  === "freebie"  && 
                   <Menu.Item key={9}>Applicable Modes</Menu.Item>
                }
              </Menu>
            </Form.Item>
          </NewContainer>
        </Col>
        <Col xl={18} lg={18} md={24} sm={24} xs={24}>
          <CriteriaContent>{SelectedCriteriaType[currentMenu]}</CriteriaContent>
        </Col>
      </Row>
    </>
  );
};

export default CriteriaTypes;
