import styled from 'styled-components';
import { Input,Tabs } from 'antd';

export const CustomTabs = styled(Tabs)`
    .ant-tabs-tab-btn {
        padding: 0px 10px;
    }
    .ant-tabs-nav-list {
        border-bottom: 1px solid rgba(0, 11, 52, 0.3) !important;
    }
`
export const InfoCardContainer = styled.div`
  height: 110px;
  background: #fff;
  border-radius: 6.5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InfoCardTitle = styled.h1`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #000b34 !important;
  margin: 0px 0px 20px 0px;
  opacity: 0.8;
`;

export const InfoCardAmount = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.2px;
  text-align: left;
  color: #0c3784;
  margin: 0;
`;

export const PercentageText = styled.h6`
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.2px;
  text-align: left;
  margin: 5px 0px 0px 5px;
  color: #0eb929;
`;

export const DaysText = styled.h6`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #000b34;
  opacity: 0.6;
  margin: 5px 0px 0px 0px;
`;

export const TableContainer = styled.div`
  background: #fff;
  height: auto;
  border-radius: 6.5px;
  padding: 20px;

  .ant-input-group-addon {
    background: #f0f6fd;
    border-top-left-radius: 6.5px;
    border-bottom-left-radius: 6.5px;
  }

  .ant-select-selection-item {
    color: #0c3784 !important;
    font-weight: 600;
    opacity: 1;
  }

  .ant-select-arrow {
    color: #0c3784 !important;
  }

  .ant-input {
    border-radius: 6.5px;
  }

  .ant-table-row {
    vertical-align: baseline;
  }
`;

export const CustomInput = styled(Input)`
  border-radius: 10px !important;
`;

export const Text = styled.h6`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #0c3784;
`;

export const TypeOfAmount = styled.div`
  .amount_type_title {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0,11, 52, 0.6);
    padding-left: 8px;
  }

  .border-left{
    border-left: 1px solid #DFE0EB;
  }

  .amount_type_amount{
    font-weight: 700;
    color: #0C3784;
  }
`;

export const AddOnBeforeText = styled.div`
    width: 90px;
    text-align: left;
    margin-left: 24px;
    color: #0C3784;
`

