import styled from 'styled-components';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Select,
  Input,
  InputNumber,
  Form,
  Table,
  Radio,
  Card,
  Checkbox,
  DatePicker,
} from 'antd';
// import { fadeIn } from 'react-animations';
import moment from 'moment';
import media from './media';

const { Search, TextArea } = Input;
const { RangePicker } = DatePicker;

export const Heading = styled.p`
  font-size: ${(props) => {
    switch (props.size) {
      case 1:
        return '24px';
      case 2:
        return '20px';
      case 'main':
        return '36px';
      default:
        return '24px';
    }
  }};
  line-height: ${(props) => {
    switch (props.size) {
      case 1:
        return '32px';
      case 2:
        return '140%';
      case 'main':
        return '24px';
      default:
        return '32px';
    }
  }};
  font-weight: ${(props) => {
    switch (props.size) {
      case 1:
        return '700';
      case 2:
        return 'bold';
      case 'main':
        return 'bold';
      default:
        return '700';
    }
  }};
  color: #263f97;
  margin: 0;
  letter-spacing: 0.2px;

  ${media.mobile`
    font-size: ${(props) => {
      switch (props.size) {
        case 1:
          return '20px';
        case 2:
          return '18px';
        case 'main':
          return '42px';
        default:
          return '20px';
      }
    }};
  line-height: ${(props) => {
    switch (props.size) {
      case 1:
        return '30px';
      case 2:
        return '27px';
      case 'main':
        return '42px';
      default:
        return '30px';
    }
  }};
  font-weight: ${(props) => {
    switch (props.size) {
      case 1:
        return '700';
      case 2:
        return '600';
      case 'main':
        return 'bold';
      default:
        return '700';
    }
  }};
  `}
`;

export const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${(props) => {
    switch (props.direction) {
      case 1:
        return 'column';

      default:
        return 'row';
    }
  }};
`;
export const SpaceAroundContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: ${(props) => {
    switch (props.direction) {
      case 1:
        return 'column';

      default:
        return 'row';
    }
  }};
`;

export const SpaceEvenlyContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: ${(props) => {
    switch (props.direction) {
      case 1:
        return 'column';

      default:
        return 'row';
    }
  }};
`;

export const GradButton = styled(Button)`
  background: ${(props) => {
    switch (props.bg) {
      case 'blue-gradient':
        return 'linear-gradient(262.05deg, #116FDD -27.59%, #6CB0FF 15.93%, #429DF1 43.72%, #407DDA 89.1%, #006BCE 111.12%, #074AAE 150.22%)';
      default:
        return '#263F97';
    }
  }} !important;
  border-color: ${(props) => {
    switch (props.border) {
      case 'standard':
        return 'transparent';
      default:
        return '#263F97';
    }
  }} !important;
  color: ${(props) => {
    switch (props.color) {
      case 'standard':
        return '#fff';
      default:
        return '#fff';
    }
  }} !important;
  font-weight: ${(props) => {
    switch (props.wt) {
      case 'bold':
        return 'bold';
      default:
        return 'normal';
    }
  }} !important;
  text-transform: ${(props) => {
    switch (props.tf) {
      case 'uppercase':
        return 'uppercase';
      case 'cap':
        return 'capitalise';
      default:
        return 'lowercase';
    }
  }} !important;
`;

export const FlexColContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: normal;
  color: #263f97;
  font-size: 14px;
`;
export const FlexBox = styled.div`
  display: flex;
`;
export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexEndContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const EditIcon = styled(EditOutlined)`
  color: #1890ff;
  cursor: pointer;
`;

export const DeleteIcon = styled(DeleteOutlined)`
  color: #1890ff;
  cursor: pointer;
`;

export const AddIcon = styled(PlusOutlined)`
  color: #1890ff;
  cursor: pointer;
`;

export const EyeIcon = styled(EyeOutlined)`
  color: #808080;
  margin-left: 8px;
  cursor: pointer;

  :hover {
    color: #272727;
  }
`;

export const Span = styled.span`
  font-size: 15px;
  line-height: 23px;
  color: #263f97;
`;

export const PickrrButton = styled(Button)`
  background-color: ${(props) => {
    switch (props.color) {
      case 'primary':
        return '#263F97';
      case 'secondary':
        return 'transparent';
      case 'grey':
        return '#808080';
      case 'peach':
        return '#ff756c';
      case 'cancel':
        return '#EDF0F9';
      case 'outline':
        return '#EDF0F9';
      case 'white':
        return '#fff';
      case 'white-red':
        return '#fff';
      case 'error':
        return '#fff';
      case 'soft-blue':
        return 'rgb(38 63 151)';
      case 'light-focus':
        return '#EDF0F9';
      case 'hard-blue':
        return '#EDF0F9';
      case 'checkout-blue':
        return '#0C64DB';
      case 'blue':
        return '#0051BF';
      case 'transparent':
        return 'transparent !important';
      case 'purple':
        return 'rgba(111, 87, 233, 1)'
      default:
        return '#263F97';
    }
  }} !important;
  border-color: ${(props) => {
    switch (props.color) {
      case 'primary':
        return '#263F97';
      case 'secondary':
        return '#808080';
      case 'grey':
        return '#808080';
      case 'peach':
        return '#ff756c';
      case 'cancel':
        return '#EDF0F9';
      case 'outline':
        return 'transparent';
      case 'white':
        return '#fff';
      case 'white-red':
        return '#fff';
      case 'error':
        return '#FF756C';
      case 'soft-blue':
        return '#7E8FCC';
      case 'light-focus':
        return '#EDF0F9';
      case 'hard-blue':
        return '#263F97';
      case 'checkout-blue':
        return '#0C64DB';
      case 'transparent':
        return '#0C64DB';
      default:
        return '#263F97';
    }
  }} !important;
  color: ${(props) => {
    switch (props.color) {
      case 'secondary':
        return '#808080';
      case 'cancel':
        return '#7D8CC1';
      case 'outline':
        return '#263F97';
      case 'white':
        return '#263F97';
      case 'white-red':
        return '#DE1306';
      case 'error':
        return '#FF756C';
      case 'light-focus':
        return '#263F97';
      case 'hard-blue':
        return '#263F97';
      case 'blue':
        return '#ffffff';
      case 'transparent':
          return '#0C64DB';
      default:
        return '#fff';
    }
  }} !important;
  font-weight: ${(props) => {
    switch (props.weight) {
      case 'bold':
        return 'bold';
      case 'normal':
        return 'normal';
      default:
        return 'normal';
    }
  }} !important;

  font-size: 14px;
  line-height: 24px;
  border-radius: 8px;
  letter-spacing: 0.12px;
  text-transform: uppercase;

  &:disabled {
    color: #00000040 !important;
    background: #9EB1E9 !important;
    border-color: #d9d9d9 !important;
    text-shadow: none !important;
    box-shadow: none !important;
  }
`;

export const SmallHeading = styled.p`
  font-size: ${(props) => {
    switch (props.size) {
      case 1:
        return '18px';
      case 2:
        return '16px';
      case 3:
        return '12px';
      case 4:
        return '20px';
      default:
        return '18px';
    }
  }};
  font-weight: 600;
  color: #263f97;
`;

export const InputField = styled.div`
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #707070;
  }
  .ant-input {
    border: 1px solid #707070;
    font-size: 12px;
    font-weight: 600;
    padding: 8px 16px;
    color: #272727;
  }
  .ant-input-number {
    border: 1px solid #707070;
    font-size: 12px;
    font-weight: 600;
    padding: 2px;
    border-radius: 5px;
    color: #272727;
  }
`;

// export const FadeIn = styled.div`
//   animation: 1s ${keyframes`${fadeIn}`};
// `;

export const Asterisk = styled.span`
  color: #ff4d4f;
  margin-right: 4px;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
`;

export const CustomSelect = styled(Select)`
  border: 1px solid #dfe0eb;
  border-radius: 5px;
`;
export const CustomInput = styled(Input)`
  border: 2px solid #dfe0eb;
  border-radius: 5px;
`;
export const CustomInputPassword = styled(Input.Password)`
  border: 2px solid #dfe0eb;
  border-radius: 5px;
`;

export const CustomInputNumber = styled(InputNumber)`
  border: 2px solid #dfe0eb;
  border-radius: 5px;
  width: 100%;
`;

export const PickrrHeading = styled.p`
  font-weight: bold;
  font-size: ${(props) => {
    switch (props.size) {
      case 1:
        return '42px';
      case 2:
        return '32px';
      case 3:
        return '28px';
      case 4:
        return '20px';
      case 5:
        return '16px';
      case 6:
        return '14px';
      default:
        return '36px';
    }
  }};
  line-height: ${(props) => {
    switch (props.size) {
      case 1:
        return '42px';
      case 2:
        return '32px';
      case 3:
        return '28px';
      case 4:
        return '20px';
      case 6:
        return '20px';
      default:
        return '36px';
    }
  }};
  letter-spacing: ${(props) => {
    switch (props.size) {
      case 1:
        return '-0.03em';
      case 4:
        return '-0.01em';
      default:
        return '-0.03em';
    }
  }};
  color: #263f97;
  margin: 0;

  ${media.mobile`
  font-size:24px;
  `}
`;

export const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;
export const DownloadButton = styled(Button)`
  border: 1px solid #263f97 !important;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #263f97 !important;
  height: 40px;
  &:hover {
    color: #263f97 !important;
    border: 1px solid #263f97 !important;
  }
`;

export const CustomForm = styled(Form)`
  .ant-form-item-label > label {
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    color: #263f97;
  }
`;
export const CustomSearch = styled(Search)`
  .ant-input-group-addon {
    background-color: #edf0f9;
    border-radius: 8px 0px 0px 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    /* identical to box height, or 17px */

    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #263f97;
  }
  .ant-input-affix-wrapper,
  .ant-picker-range,
  .ant-select-selector {
    border-radius: 0px 0px 0px 0px !important;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border-radius: 0px 8px 8px 0px !important;
  }
  .ant-input-affix-wrapper-lg .ant-input-search-icon {
    display: none;
  }
  .anticon {
    color: #263f97;
  }
`;
export const CustomTable = styled(Table)`
  .ant-table {
    border: 1px solid rgba(38, 63, 151, 0.1);
    border-radius: 12px;
  }
  .ant-table-thead > tr > th {
    background: #fff;
    color: rgba(13, 57, 133, 0.5);
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
    /* text-transform: uppercase; */
    word-break: none;
  }

  .ant-table-tbody > tr > td {
    word-wrap: break-word;
    word-break: break-word;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #0c3784;
    text-align: left;
  }
  .ant-table-tbody > tr {
    vertical-align: center;
    border: 1px solid rgba(38, 63, 151, 0.1);
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #f1faff;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 12px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 12px;
  }
  .ant-table-tbody > tr > td > td:first-child {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }
  .ant-table-thead > tr > th:last-child {
    text-align: center;
  }
`;

export const DatePickerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  .ant-picker {
    border: 0;
  }
`;

export const CustomRadio = styled(Radio)`
  color: #263f97;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  .ant-radio-checked .ant-radio-inner {
    border-color: #ff756c;
  }
  .ant-radio-inner::after {
    background-color: #ff756c;
  }
`;
export const CustomCheckBox = styled(Checkbox.Group)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ff756c !important;
    border-color: #ff756c !important;
  }
  .ant-checkbox-wrapper {
    color: #263797;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #ff756c !important;
  }
  ${media.mobile`
   &.ant-checkbox-group {
     display: flex;
     justify-content:space-between;
  }
  `};
`;

export const DocumentationDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #38446d;
  text-align: justify;
`;

export const DocumentationImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  background-color: #edf0f9;
  padding: 8px;
`;

export const FlexColCenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const HeaderIcon = styled.div`
  width: 48px;
  height: 48px;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

export const PickrrCard = styled(Card)`
  border-radius: 12px;
  .ant-card-body {
    padding-top: ${(props) => props.pt || '24px'};
    padding-left: ${(props) => props.pl || '24px'};
    padding-right: ${(props) => props.pr || '24px'};
    padding-bottom: ${(props) => props.pb || '24px'};
  }
`;

export const CustomTextArea = styled(TextArea)`
  border: 2px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 5px;
`;

export const CustomDatePicker = styled(RangePicker)`
  &.ant-picker {
    border: 1px solid #EAEAEA;
    border-radius: 8px;
  }
`;

export const CustomDashboardTable = styled(Table)`
  /* .ant-table {
    border: 1px solid rgba(38, 63, 151, 0.1);
    border-radius: 12px;
  } */
  .ant-table-thead > tr > th {
    background: #fff;
    color: rgba(36, 58, 129);
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
    /* text-transform: uppercase; */
    word-break: none;
  }

  .ant-table-tbody > tr > td {
    word-wrap: break-word;
    word-break: break-word;

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: rgba(62,70,107);
    text-align: left;
  }
  .ant-table-tbody > tr {
    vertical-align: center;
    border: 1px solid rgba(38, 63, 151, 0.1);
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #f1faff;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 12px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 12px;
  }
  .ant-table-tbody > tr > td > td:first-child {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }
  /* .ant-table-thead > tr > th:last-child {
    text-align: center;
  } */
  .ant-table-tbody > tr > td {
    border:none ;
  }
`;
// // border:none ;
// .ant-radio-button-wrapper-checked {
//   // background: rgba(12, 100, 219, 0.1);
//     color: #125CCC;
//     /* color:green; */
//     border-radius:10px;
//     border:none ;
//   }
//   .ant-radio-button-wrapper{
//     border:none
//   }

export const DashboardCustumRadio = styled(CustomRadio.Group)`
  //  background: #0C64DB1A
// .ant-radio-button .ant-radio-button-checked{
//   background: #0C64DB1A;
// }
`