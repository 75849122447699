import React, { useContext, useEffect, useState } from "react";
import { CustomTabs } from "../styled";
import moment from "moment";
import { CustomDatePicker } from "components/UIElements";
import { message } from "antd";
import RefundsNew from "./RefundsTabNew";
import RefundsTable from "./RefundTable";
const { TabPane } = CustomTabs;
const RefundTabs = () => {
  const [activeKey, setActiveKey] = useState("1");

  const handleTabChange = (activeKey: any) => {
    setActiveKey(activeKey);
  };
  return (
    <>
      <CustomTabs onChange={handleTabChange}>
        <TabPane tab="Transaction HISTORY" key="1">
          <RefundsNew />
        </TabPane>
        <TabPane tab="Bulk refunds" key="2">
          <RefundsTable />
        </TabPane>
      </CustomTabs>
    </>
  );
};

export default RefundTabs;
